import(/* webpackMode: "eager" */ "/opt/render/project/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/opt/render/project/src/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/opt/render/project/src/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useReverification","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/opt/render/project/src/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["PromisifiedAuthProvider"] */ "/opt/render/project/src/node_modules/@clerk/nextjs/dist/esm/client-boundary/PromisifiedAuthProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile","Waitlist"] */ "/opt/render/project/src/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../public/fonts/MarkForMC-Lt.ttf\",\"weight\":\"300\"},{\"path\":\"../public/fonts/MarkForMC.ttf\",\"weight\":\"400\"},{\"path\":\"../public/fonts/MarkForMC-Med.ttf\",\"weight\":\"500\"},{\"path\":\"../public/fonts/MarkForMC-Bold.ttf\",\"weight\":\"700\"}]}],\"variableName\":\"markForMC\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/render/project/src/node_modules/react-hot-toast/dist/index.mjs");
